@import '@farol-ds/tokens/index.scss';

.container {
  padding: 0 !important;

  &__top {
    display: flex;
    flex-direction: column;
    gap: $spacing-5xs;
    margin-bottom: $spacing-3xs;
  }
}

.card {
  &__name {
    color: $color-base-low-pure;
    margin-bottom: $spacing-6xs;
  }

  &__info {
    display: flex;
    flex-direction: row;
    margin-bottom: $spacing-3xs;
    gap: $spacing-6xs;

    &__age {
      white-space: nowrap;
    }

    &__states {
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      line-clamp: 1;
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .card__button {
    width: 100%;
  }
}
