@import '@farol-ds/tokens/index.scss';

.container {
  justify-content: center;
}

.header {
  display: flex;
  flex-direction: column;
  margin-top: var(--fds-spacing-sm);
  margin-bottom: var(--fds-spacing-2xs);
}

.title {
  text-align: center;
  margin-bottom: var(--fds-spacing-3xs);
}

.text {
  text-align: center;
}

@media screen and (min-width: $breakpoint-sm) {
  .header {
    margin-top: var(--fds-spacing-md);
  }
}

@media screen and (min-width: $breakpoint-md) {
  .header {
    margin-top: var(--fds-spacing-xl);
    margin-bottom: var(--fds-spacing-xs);
  }
}
