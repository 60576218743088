@import '@farol-ds/tokens/index.scss';

.search {
  display: flex;
  justify-content: center;
  margin-bottom: var(--fds-spacing-xs);
}

.hasError {
  padding-top: 10px;
  padding-left: 10px;
}

@media screen and (min-width: $breakpoint-sm) {
  .search {
    margin-bottom: var(--fds-spacing-md);
  }
}

@media screen and (min-width: $breakpoint-md) {
  .search {
    margin-bottom: var(--fds-spacing-xl);
  }
}
