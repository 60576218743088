.debugInfo {
  &.title {
    color: var(--fds-color-base-low-pure);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &.titleExperiments {
    margin-top: var(--fds-spacing-4xs);
  }

  &.admin {
    display: flex;
    align-items: center;
  }

  &.field {
    margin-top: var(--fds-spacing-6xs);
    font-size: var(--fds-font-size-2xs);
    gap: var(--fds-spacing-3xs);

    &.value {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
