@import '@farol-ds/tokens/index.scss';

.col {
  &:not(:last-child) {
    margin-bottom: var(--fds-spacing-3xs);
  }
}

.title:not(a) {
  color: var(--fds-color-base-low-pure);
}

.list {
  li {
    display: inline-block;
    margin-top: var(--fds-spacing-5xs);

    a {
      padding-top: var(--fds-spacing-5xs);
      padding-bottom: var(--fds-spacing-5xs);
    }
  }

  .sep {
    margin-inline: var(--fds-spacing-6xs);
  }
}

@media only screen and (min-width: $breakpoint-sm) {
  .col:last-child,
  .col:nth-last-child(2) {
    margin-bottom: unset;
  }
}
