@import '@farol-ds/tokens/index.scss';

.container {
  min-width: 100%;
  width: 100%;
  border-top: solid var(--fds-border-width-sm) var(--fds-color-base-high-2);
  padding-block: var(--fds-spacing-3xs);
}

.root {
  display: flex;
  flex-direction: column;
  gap: var(--fds-spacing-4xs);
}

@media only screen and (min-width: $breakpoint-sm) {
  .root {
    flex-direction: row;
  }
}
