@import '@farol-ds/tokens/index.scss';

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (min-width: $breakpoint-sm) {
  .root {
    justify-content: flex-end;
    gap: unset;
  }
}
