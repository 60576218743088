@import '@farol-ds/tokens/index.scss';

.container {
  margin-bottom: $spacing-md;
}

.title {
  margin-bottom: $spacing-3xs;
}

@media screen and (min-width: $breakpoint-md) {
  .container {
    margin-bottom: $spacing-xl;
  }

  .title {
    margin-bottom: $spacing-2xs;
  }
}
