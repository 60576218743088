@import '@farol-ds/tokens/index.scss';

.container {
  padding: var(--fds-spacing-2xs) 0;
}

.name {
  margin-bottom: var(--fds-spacing-5xs);
}

.iconButtons {
  display: flex;
  flex-direction: row;
}

.description {
  font-size: var(--fds-font-size-2xs);
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: var(--fds-spacing-2xs);

  &Main {
    display: flex;
    align-items: center;
    gap: var(--fds-spacing-3xs);
  }

  &MonitoringBtn {
    display: none;
  }
}

.containerPadding {
  & .actionsContainer {
    padding-left: 0;
    padding-right: 0;
  }
}

.actionsContainer {
  display: flex;
  gap: var(--fds-spacing-3xs);
}

@media screen and (max-width: $breakpoint-sm) {
  .container {
    padding: var(--fds-spacing-2xs) 0 var(--fds-spacing-xs) 0;
  }

  .actions {
    margin-top: var(--fds-spacing-3xs);
  }

  .fixedActionsButtonWrapper {
    position: fixed;
    left: 0;
    bottom: 0;
    padding: var(--fds-spacing-5xs) var(--fds-spacing-3xs);
    min-width: 100%;
    min-height: var(--fds-spacing-xl);
    background-color: var(--fds-color-base-high-pure);
    z-index: 2;

    & .actionsContainer {
      padding-left: 0;
      padding-right: 0;
      gap: 0;
    }

    & .fixedActionsButtonMobile {
      width: 100%;
    }
  }
}

@media screen and (min-width: $breakpoint-sm) {
  .actions {
    &MonitoringBtn {
      display: inline-flex;
    }

    &MonitoringBtnMobile {
      display: none;
    }
  }

  .container {
    padding: var(--fds-spacing-sm) 0 var(--fds-spacing-xs) 0;
  }

  .fixedActionsButtonWrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: unset;
    display: flex;
    flex-direction: row;
    padding: var(--fds-spacing-4xs) 0;
    background-color: var(--fds-color-base-high-pure);
    min-width: 100%;
    border-bottom: var(--fds-border-width-sm) solid var(--fds-color-base-high-2);
    z-index: 2;
  }
}
