@import '@farol-ds/tokens/index.scss';

.container {
  min-width: 100%;
  width: 100%;
  border-top: solid var(--fds-border-width-sm) var(--fds-color-base-high-2);
  padding-block: var(--fds-spacing-2xs);
}

@media only screen and (min-width: $breakpoint-sm) {
  .container {
    padding-top: var(--fds-spacing-3xs);
  }
}
