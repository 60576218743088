@import '@farol-ds/tokens/index.scss';

.faq {
  width: 100%;
  margin-top: $spacing-3xs;
  margin-bottom: $spacing-sm;
}

@media screen and (min-width: $breakpoint-sm) {
  .faq {
    margin-top: $spacing-md;
    margin-bottom: $spacing-md;
  }
}

@media screen and (min-width: $breakpoint-md) {
  .faq {
    margin-top: $spacing-xl;
    margin-bottom: $spacing-xl;
  }
}
