@import '@farol-ds/tokens/index.scss';

.section {
  margin-top: var(--fds-spacing-xs);
}

.title {
  margin-bottom: var(--fds-spacing-6xs);
}

.titleWithoutDescription {
  margin-bottom: var(--fds-spacing-3xs);
}

.description {
  margin-bottom: var(--fds-spacing-3xs);
}

@media (min-width: $breakpoint-md) {
  .title {
    margin-bottom: var(--fds-spacing-5xs);
  }

  .titleWithoutDescription {
    margin-bottom: var(--fds-spacing-3xs);
  }

  .description {
    margin-bottom: var(--fds-spacing-2xs);
  }

  .section {
    margin-top: var(--fds-spacing-md);
  }

  .sectionExperiment {
    margin-bottom: var(--fds-spacing-xl);
  }
}
