@import '@farol-ds/tokens/index.scss';

.root {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: var(--fds-spacing-4xs);
  flex: 1;
}

.brand {
  align-self: flex-start;
}

@media only screen and (min-width: $breakpoint-sm) {
  .root {
    align-items: center;
    flex-direction: row;
  }

  .copyText {
    margin-right: var(--fds-spacing-md);
  }

  .brand {
    align-self: center;
  }
}
