@import '@farol-ds/tokens/index.scss';

.section {
  margin-bottom: $spacing-sm;
}

.background {
  background-color: var(--fds-color-base-high-1);
}

.header {
  margin-top: $spacing-md;
  margin-bottom: $spacing-3xs;
}

.title {
  margin-bottom: $spacing-6xs;
}

@media screen and (min-width: $breakpoint-sm) {
  .section {
    margin-bottom: $spacing-md;
  }
}

@media screen and (min-width: $breakpoint-md) {
  .section {
    margin-bottom: $spacing-xl;
  }

  .header {
    margin-top: $spacing-xl;
    margin-bottom: $spacing-xl;
  }
}
