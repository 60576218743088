@import '@farol-ds/tokens/index.scss';

.listTitle {
  margin-bottom: var(--fds-spacing-2xs);

  &__withSub {
    margin-bottom: var(--fds-spacing-5xs);
  }
}

.listSubtitle {
  margin-bottom: var(--fds-spacing-2xs);
}

.personEntityCard {
  margin-bottom: var(--fds-spacing-xs);
}
