$border-radius-xs: var(--fds-border-radius-xs);
$border-radius-sm: var(--fds-border-radius-sm);
$border-radius-md: var(--fds-border-radius-md);
$border-radius-lg: var(--fds-border-radius-lg);
$border-radius-pill: var(--fds-border-radius-pill);
$border-radius-circle: var(--fds-border-radius-circle);
$border-width-sm: var(--fds-border-width-sm);
$border-width-md: var(--fds-border-width-md);
$border-width-lg: var(--fds-border-width-lg);
$breakpoint-xs: 0px;
$breakpoint-sm: 768px;
$breakpoint-md: 1024px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1440px;
$color-base-low-1: var(--fds-color-base-low-1);
$color-base-low-2: var(--fds-color-base-low-2);
$color-base-low-3: var(--fds-color-base-low-3);
$color-base-low-4: var(--fds-color-base-low-4);
$color-base-low-pure: var(--fds-color-base-low-pure);
$color-base-high-1: var(--fds-color-base-high-1);
$color-base-high-2: var(--fds-color-base-high-2);
$color-base-high-3: var(--fds-color-base-high-3);
$color-base-high-4: var(--fds-color-base-high-4);
$color-base-high-pure: var(--fds-color-base-high-pure);
$color-brand-primary-1: var(--fds-color-brand-primary-1);
$color-brand-primary-2: var(--fds-color-brand-primary-2);
$color-brand-primary-3: var(--fds-color-brand-primary-3);
$color-brand-primary-4: var(--fds-color-brand-primary-4);
$color-brand-primary-pure: var(--fds-color-brand-primary-pure);
$color-brand-secondary-1: var(--fds-color-brand-secondary-1);
$color-brand-secondary-2: var(--fds-color-brand-secondary-2);
$color-brand-secondary-3: var(--fds-color-brand-secondary-3);
$color-brand-secondary-4: var(--fds-color-brand-secondary-4);
$color-brand-secondary-pure: var(--fds-color-brand-secondary-pure);
$color-brand-tertiary-1: var(--fds-color-brand-tertiary-1);
$color-brand-tertiary-2: var(--fds-color-brand-tertiary-2);
$color-brand-tertiary-3: var(--fds-color-brand-tertiary-3);
$color-brand-tertiary-4: var(--fds-color-brand-tertiary-4);
$color-brand-tertiary-pure: var(--fds-color-brand-tertiary-pure);
$color-feedback-negative-1: var(--fds-color-feedback-negative-1);
$color-feedback-negative-2: var(--fds-color-feedback-negative-2);
$color-feedback-negative-3: var(--fds-color-feedback-negative-3);
$color-feedback-negative-4: var(--fds-color-feedback-negative-4);
$color-feedback-negative-pure: var(--fds-color-feedback-negative-pure);
$color-feedback-positive-1: var(--fds-color-feedback-positive-1);
$color-feedback-positive-2: var(--fds-color-feedback-positive-2);
$color-feedback-positive-3: var(--fds-color-feedback-positive-3);
$color-feedback-positive-4: var(--fds-color-feedback-positive-4);
$color-feedback-positive-pure: var(--fds-color-feedback-positive-pure);
$color-feedback-info-1: var(--fds-color-feedback-info-1);
$color-feedback-info-2: var(--fds-color-feedback-info-2);
$color-feedback-info-3: var(--fds-color-feedback-info-3);
$color-feedback-info-4: var(--fds-color-feedback-info-4);
$color-feedback-info-pure: var(--fds-color-feedback-info-pure);
$color-feedback-warning-1: var(--fds-color-feedback-warning-1);
$color-feedback-warning-2: var(--fds-color-feedback-warning-2);
$color-feedback-warning-3: var(--fds-color-feedback-warning-3);
$color-feedback-warning-4: var(--fds-color-feedback-warning-4);
$color-feedback-warning-pure: var(--fds-color-feedback-warning-pure);
$c-brand-text-color-positive: var(--fds-c-brand-text-color-positive);
$c-brand-text-color-negative: var(--fds-c-brand-text-color-negative);
$c-overlay-background: var(--fds-color-base-low-pure);
$extra-color-visited: var(--fds-extra-color-visited);
$extra-focus-outline: var(--fds-extra-focus-outline);
$extra-focus-outline-offset: var(--fds-extra-focus-outline-offset);
$duration-fast-1: var(--fds-duration-fast-1);
$duration-fast-2: var(--fds-duration-fast-2);
$duration-medium-1: var(--fds-duration-medium-1);
$duration-medium-2: var(--fds-duration-medium-2);
$duration-slow-1: var(--fds-duration-slow-1);
$duration-slow-2: var(--fds-duration-slow-2);
$ease-productive: var(--fds-ease-productive);
$ease-expressive: var(--fds-ease-expressive);
$ease-loop: var(--fds-ease-loop);
$rotate-xs: var(--fds-rotate-xs);
$rotate-sm: var(--fds-rotate-sm);
$rotate-md: var(--fds-rotate-md);
$rotate-lg: var(--fds-rotate-lg);
$rotate-xl: var(--fds-rotate-xl);
$scale-xs: var(--fds-scale-xs);
$scale-sm: var(--fds-scale-sm);
$scale-md: var(--fds-scale-md);
$scale-lg: var(--fds-scale-lg);
$scale-xl: var(--fds-scale-xl);
$opacity-level-1: var(--fds-opacity-level-1);
$opacity-level-2: var(--fds-opacity-level-2);
$opacity-level-3: var(--fds-opacity-level-3);
$opacity-level-4: var(--fds-opacity-level-4);
$opacity-level-5: var(--fds-opacity-level-5);
$shadow-level-1: var(--fds-shadow-level-1);
$shadow-level-2: var(--fds-shadow-level-2);
$shadow-level-3: var(--fds-shadow-level-3);
$shadow-level-4: var(--fds-shadow-level-4);
$shadow-level-5: var(--fds-shadow-level-5);
$spacing-6xs: var(--fds-spacing-6xs);
$spacing-5xs: var(--fds-spacing-5xs);
$spacing-4xs: var(--fds-spacing-4xs);
$spacing-3xs: var(--fds-spacing-3xs);
$spacing-2xs: var(--fds-spacing-2xs);
$spacing-xs: var(--fds-spacing-xs);
$spacing-sm: var(--fds-spacing-sm);
$spacing-md: var(--fds-spacing-md);
$spacing-lg: var(--fds-spacing-lg);
$spacing-xl: var(--fds-spacing-xl);
$spacing-2xl: var(--fds-spacing-2xl);
$spacing-3xl: var(--fds-spacing-3xl);
$spacing-4xl: var(--fds-spacing-4xl);
$spacing-5xl: var(--fds-spacing-5xl);
$spacing-6xl: var(--fds-spacing-6xl);
$font-family-base: var(--fds-font-family-base);
$font-family-variant: var(--fds-font-family-variant);
$font-size-4xs: var(--fds-font-size-4xs);
$font-size-3xs: var(--fds-font-size-3xs);
$font-size-2xs: var(--fds-font-size-2xs);
$font-size-xs: var(--fds-font-size-xs);
$font-size-sm: var(--fds-font-size-sm);
$font-size-md: var(--fds-font-size-md);
$font-size-lg: var(--fds-font-size-lg);
$font-size-xl: var(--fds-font-size-xl);
$font-size-2xl: var(--fds-font-size-2xl);
$font-size-3xl: var(--fds-font-size-3xl);
$font-size-4xl: var(--fds-font-size-4xl);
$font-size-5xl: var(--fds-font-size-5xl);
$font-size-6xl: var(--fds-font-size-6xl);
$font-weight-bold: var(--fds-font-weight-bold);
$font-weight-semibold: var(--fds-font-weight-semibold);
$font-weight-regular: var(--fds-font-weight-regular);
$letter-spacing-sm: var(--fds-letter-spacing-sm);
$letter-spacing-md: var(--fds-letter-spacing-md);
$line-height-xs: var(--fds-line-height-xs);
$line-height-sm: var(--fds-line-height-sm);
$line-height-md: var(--fds-line-height-md);
$line-height-lg: var(--fds-line-height-lg);