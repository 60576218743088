@import '@farol-ds/tokens/index.scss';

.topic {
  margin-top: $spacing-xs;
}

.item {
  display: inline-block;
  margin-top: $spacing-3xs;
}

.list {
  width: 100%;
}
