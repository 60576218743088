@import '@farol-ds/tokens/index.scss';

.body {
  padding: 0 var(--fds-spacing-3xs) var(--fds-spacing-2xs) var(--fds-spacing-3xs);
}

.title {
  font-weight: var(--fds-font-weight-semibold);
  font-size: var(--fds-font-size-sm);
  margin-bottom: var(--fds-spacing-5xs);
  text-align: center;
  color: var(--fds-color-base-low-pure);

  &Image {
    margin: auto;
  }
}

.description {
  font-size: var(--fds-font-size-xs);
  margin-bottom: var(--fds-spacing-5xs);
  text-align: center;
  color: var(--fds-color-base-low-2);
}

.section {
  display: flex;
  margin-bottom: var(--fds-spacing-5xs);

  &Icon {
    margin-right: var(--fds-spacing-5xs);
    color: var(--fds-color-base-low-pure);
  }

  &Title {
    color: var(--fds-color-base-low-pure);
    font-weight: var(--fds-font-weight-semibold);
    font-size: var(--fds-font-size-2xs);
  }

  &Value {
    color: var(--fds-color-base-low-2);
    font-weight: var(--fds-font-weight-regular);
    font-size: var(--fds-font-size-2xs);
  }
}

.ul {
  padding: 0;
}
