@import '@farol-ds/tokens/index.scss';

.pagination {
  display: flex;
  justify-content: flex-end;
}

.item {
  width: 100%;
  max-width: 88%;
  padding-left: var(--fds-spacing-3xs);
}

.item:last-child {
  max-width: 100%;
  padding-right: var(--fds-spacing-3xs);
}

.cardCarousel {
  margin-bottom: 0;
}

@media (min-width: $breakpoint-sm) {
  .list {
    gap: var(--fds-spacing-xs);
  }

  .item,
  .item:last-child {
    max-width: 45%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .blockedDisplay {
    display: none;
  }
}
