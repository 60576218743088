@import '@farol-ds/tokens/index.scss';

.menu {
  display: block;
}

.footer {
  margin-top: var(--fds-spacing-2xl);
}

@media (min-width: $breakpoint-md) {
  .footer {
    margin-top: var(--fds-spacing-5xl);
  }
}
