@import '@farol-ds/tokens/index.scss';

.title {
  margin-top: var(--fds-spacing-sm);
  text-align: center;
}

.list {
  margin-bottom: var(--fds-spacing-sm);
}

.item {
  display: inline-grid;
}

.image {
  width: 100%;
  margin-top: var(--fds-spacing-2xs);
  margin-bottom: var(--fds-spacing-2xs);
}

.imagetitle {
  text-align: center;
  margin-bottom: var(--fds-spacing-5xs);
}

.description {
  text-align: center;
}

@media screen and (min-width: $breakpoint-sm) {
  .title {
    margin-top: var(--fds-spacing-md);
    text-align: center;
  }

  .image {
    margin-top: 0px;
  }

  .list {
    margin-top: var(--fds-spacing-sm);
    margin-bottom: var(--fds-spacing-md);
  }
}

@media screen and (min-width: $breakpoint-md) {
  .title {
    margin-top: var(--fds-spacing-xl);
    text-align: center;
  }

  .list {
    margin-bottom: var(--fds-spacing-xl);
  }
}
