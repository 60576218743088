.container {
  margin: var(--fds-spacing-sm) 0;
}

.subtitle {
  margin-bottom: var(--fds-spacing-4xs);
}

.link {
  font-size: var(--fds-font-size-2xs);
  margin-bottom: var(--fds-spacing-4xs);
  display: inline-flex;

  &.truncate {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.accordionTitle {
  font-size: var(--fds-font-size-sm);
  font-weight: var(--fds-font-weight-regular);
  margin-bottom: var(--fds-spacing-5xs);
}

.accordionSubtitle {
  font-size: var(--fds-font-size-2xs);
  font-weight: var(--fds-font-weight-regular);
}
